import { Component } from '@angular/core';

@Component({
  selector: 'app-copyright',
  styleUrls: ['./copyright.component.scss'],
  templateUrl: './copyright.component.html',
})
export class CopyrightComponent {
  public year: number = new Date().getFullYear();
}
