import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Validation obligates dot in string
export const hasDot = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    return control.value && control.value?.indexOf('.') === -1
      ? { hasNoDot: true }
      : null;
  };
};

export const validateMimesAsync = (allowedMimes: string[]): ValidatorFn => {
  return (
    control: AbstractControl
  ): Promise<{ [key: string]: boolean } | null> => {
    return new Promise(resolve => {
      const file = control.value as File;
      if (file) {
        const fileExt = file.name.split('.').pop()?.toLowerCase();
        const allowedExts = allowedMimes.map(
          mime => mime.split('/').pop()?.toLowerCase()
        );
        if (!allowedExts.includes(fileExt)) {
          resolve({ mimeTypeNotAllowed: true });
        } else {
          resolve(null);
        }
      } else {
        resolve(null);
      }
    });
  };
};

export const validateMimes = (allowedMimes: string[]): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value as File;
    if (!file?.name) {
      return null;
    }
    const fileExt = file.name.split('.').pop()?.toLowerCase();
    const allowedExts = allowedMimes.map(
      mime => mime.split('/').pop()?.toLowerCase()
    );

    return !allowedExts.includes(fileExt) ? { mimeTypeNotAllowed: true } : null;
  };
};

export const fileMaxSize = (maxSize: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const file = control.value as File;
    if (!file) {
      return null;
    }
    return file.size > maxSize ? { fileMaxSize: true } : null;
  };
};
