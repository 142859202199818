export const CONSTANTS = {
  COMPANY_SELECTED: 'company_selected',
  EMAIL: 'email',
  EXPIRATION: 'expiration',
  LEVELS: 'levels',
  PERMISSIONS: 'permissions',
  REFRESH_TOKEN: 'refresh_token',
  SOCIAL_USER: 'social_user',
  TOKEN: 'token',
  USER: 'user',
};
