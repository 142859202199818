import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import {
  IMatPaginator,
  IMatSort,
} from '@app/core/interfaces/utils/mat-table.interface';
import { DefaultMatPaginatorOptions } from '@core/utils/customMatPaginator';

@Component({
  selector: 'app-list-footer',
  styleUrl: './list-footer.component.scss',
  templateUrl: './list-footer.component.html',
})
export class ListFooterComponent {
  @Input() matSortPaginator: IMatPaginator & IMatSort = {
    ...new DefaultMatPaginatorOptions(),
    sortBy: 'id',
    sortOrder: 'desc',
    totalRows: 0,
  };
  @Input() showPaginationControls = false;
  @Input() showNoResultsMessage = false;
  @Input() showNoDataMessage = false;
  @Input() showEndOfScrollMessage = false;
  @Input() showSpinner = false;
  @Output() changePage = new EventEmitter<PageEvent>();

  onChangePage($event: PageEvent): void {
    this.changePage.emit($event);
  }
}
