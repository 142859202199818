import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '@app/core/constants/local-storage.constants';
import { Success2FACodeValidationResponse } from '@app/core/interfaces/auth/auth.interface';
import { PATHS } from '@app/core/interfaces/utils/router-path';
import { AuthService } from '@app/core/services/auth/auth.service';
import { LocalStorageService } from '@app/core/services/localStorage/local-storage.service';
import { environment } from '@environment/environment';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-internos',
  templateUrl: './internos.component.html',
})
export class InternosComponent implements OnInit, OnDestroy {
  subscription!: Subscription;
  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private recaptchaService: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    const token = this.localStorageService.getItem(CONSTANTS.TOKEN) as string;
    this.recaptchaService
      .executeAsPromise(
        environment.RECAPTCHA_V3_SITE_KEY,
        'sendValidationToDuo'
      )
      .then(recaptcha => {
        this.subscription = this.authService
          .sendValidationToDuo(token, recaptcha)
          .subscribe({
            error: () => {
              this.router.navigate([
                `/${PATHS.AUTH.BASE}/${PATHS.AUTH.ERROR.BASE}`,
              ]);
            },
            next: (response: Success2FACodeValidationResponse) => {
              if (response.token) {
                this.authService.deleteLoginData();
                this.authService.saveLoginData(response);
                const expirationId = this.localStorageService.getItem(
                  CONSTANTS.EXPIRATION
                ) as number;
                if (expirationId) {
                  this.localStorageService.removeItem(CONSTANTS.EXPIRATION);
                  clearTimeout(expirationId);
                }
                let redirectUrl = `/${PATHS.MAIN}/${PATHS.UN.MARCAS.BASE}/${PATHS.CONTROL_DE_FLETES.BASE}`;

                if (
                  this.authService.permissionSummaryList[
                    'MAIN.ROLE_WITH_COMPANIES'
                  ]
                ) {
                  redirectUrl = `/${PATHS.MAIN}/${PATHS.OFERTA_DE_CAMIONES.BASE}`;
                }

                this.router.navigate([redirectUrl]);
              }
            },
          });
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
