export const DATE_FILTERS = {
  endDate: 'endDate',
  startDate: 'startDate',
};

export const STRING_FILTERS = {
  empresaName: 'empresa_name',
  status: 'status',
};

export const IDS_FILTERS = {
  plantas: 'plantas',
  zonas: 'zonas',
};

export const SORT_COLUMNS = {
  choferDni: 'chofer.choferId',
  choferName: 'chofer.choferDesc',
  empresaName: 'empresa_name',
  empresaSubledger: 'subledger_empresa',
  fecha: 'fecha',
  nroOrdenDeCarga: 'nro_orden_carga',
  patenteSemi: 'patenteSemi.dominio',
  patenteTractor: 'patenteTractor.dominio',
  tipoDeVehiculo: 'tipoDeVehiculo.name',
};

export const STATUS_OPTIONS = {
  asignada: 'asignada',
  eliminada: 'eliminada',
  enEspera: 'en_espera',
  rechazada: 'rechazada',
};

export const STATUS_OPTIONS_LABELS = {
  asignada: 'Asignada',
  eliminada: 'Eliminada',
  en_espera: 'En Espera',
  rechazada: 'Rechazada',
};

export const STATUS_LABELS_CLASSES = {
  asignada: 'status-asignada',
  eliminada: 'status-eliminada',
  enEspera: 'status-en-espera',
  rechazada: 'status-rechazada',
};

export const STATUS_DOC_CLASSES = {
  habilitado: 'status-asignada',
  inhabilitado: 'status-rechazada',
};

export const STATUS_DOC = {
  habilitado: 'Habilitado',
  inhabilitado: 'Inhabilitado',
};

export const STATUS_DOC_COLORS = {
  habilitado: 'green',
  inhabilitado: 'red',
};

export const STATUS_DOC_ICONS = {
  habilitado: 'check',
  inhabilitado: 'close',
};
