import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CONSTANTS } from '@app/core/constants/notification.constants';
import { AuthService } from '@app/core/services/auth/auth.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { hasDot } from '@app/core/validators/custom.validators';
import { environment } from '@environment/environment';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { take } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {
  resetPasswordForm: FormGroup = new FormGroup({});
  showSpinner = false;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private recaptchaService: ReCaptchaV3Service
  ) {
    this.resetPasswordForm = this.fb.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.email, hasDot()]),
      ],
    });
  }

  get email() {
    return this.resetPasswordForm.get('email');
  }

  sendEmail(stepper: MatStepper) {
    this.recaptchaService
      .executeAsPromise(environment.RECAPTCHA_V3_SITE_KEY, 'sendEmail')
      .then(recaptcha => {
        this.authService
          .resetPassword(this.email?.value, recaptcha)
          .pipe(take(1))
          .subscribe({
            error: (err: HttpErrorResponse) => {
              this.notificationService.showNotification(
                err.error.message,
                CONSTANTS.CLOSE
              );
            },
            next: userResponse => {
              if (userResponse.statusCode === 201) {
                return stepper.next();
              }
            },
          });
      })
      .catch(err => {
        console.error(err);
        this.notificationService.showNotification(
          'Error de ReCaptcha, revise su conexión a internet o intente con otra red.'
        );
        this.showSpinner = false;
      });
  }
}
