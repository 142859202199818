import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ROLES } from '../constants/roles.constants';
import { PATHS } from '../interfaces/utils/router-path';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  async canActivate(): Promise<boolean> {
    const inInMaintenance = await this.authService.inMaintenance();

    if (inInMaintenance) {
      return false;
    }

    const user = this.authService.getUser();

    if (!user) {
      this.router.navigate([PATHS.AUTH.BASE, PATHS.AUTH.LOGIN.BASE]);
      return false;
    }

    const roleWithCompanies =
      user.role.tag === ROLES.USUARIO_TRANSPORTE ||
      user.role.tag === ROLES.USUARIO_CHOFER;

    if (roleWithCompanies && user.userCompanies.length === 0) {
      this.router.navigate([PATHS.AUTH.BASE, PATHS.AUTH.LOGIN.BASE]);
      return false;
    }

    return true;
  }
}
