import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import {
  ICheckCompanyToAssociate,
  ICompanyToAssociate,
  ICreateEmpresaBody,
  IEditEmpresaBody,
  IEmpresaData,
  IEmpresaFiltersApplied,
  IEmpresaList,
  IEmpresaListFilters,
  ISolicitudEmpresa,
  ITipoNegocio,
  IZonaDeCarga,
} from '@app/core/interfaces/empresa/empresa.interface';
import { ListRequestParams } from '@app/core/interfaces/utils/mat-table.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmpresaService {
  private basePath = 'empresas';
  constructor(protected httpService: HttpService) {}

  public getList(
    globalSearch: string,
    filters: IEmpresaFiltersApplied,
    page: number,
    limit: number,
    sortBy: string | null,
    subledger: number | null,
    sortOrder: string | null
  ): Observable<IEmpresaList> {
    const params: ListRequestParams = { limit, page };

    for (const key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key)) {
        const filter = filters[key];

        if (Array.isArray(filter)) {
          params[key] = JSON.stringify(filter);
        } else if (typeof filter === 'string') {
          params[key] = filter.trim();
        } else {
          params[key] = filter;
        }
      }
    }

    if (sortBy && sortOrder) {
      params.sortBy = sortBy;
      params.sortOrder = sortOrder;
    }

    if (globalSearch) {
      params.globalSearch = globalSearch;
    }

    if (subledger) {
      params.subledger = subledger;
    }
    return this.httpService.get<IEmpresaList>(`${this.basePath}`, params);
  }

  public getListFilters(): Observable<IEmpresaListFilters> {
    return this.httpService.get<IEmpresaListFilters>(
      `${this.basePath}/filtros`
    );
  }

  public getTipoNegocios(): Observable<ITipoNegocio[]> {
    return this.httpService.get<ITipoNegocio[]>(
      `${this.basePath}/tipo-negocios`
    );
  }

  public getZonasDeCarga(): Observable<IZonaDeCarga[]> {
    return this.httpService.get<IZonaDeCarga[]>(
      `${this.basePath}/zonas-de-carga`
    );
  }

  public create(body: ICreateEmpresaBody): Observable<ICreateEmpresaBody> {
    return this.httpService.post<ICreateEmpresaBody>(`${this.basePath}`, body);
  }

  public edit(
    body: IEditEmpresaBody,
    id: number,
    subledger?: number
  ): Observable<IEditEmpresaBody> {
    let endpoint = `${this.basePath}/${id}`;

    if (subledger) {
      endpoint += `?subledger=${subledger}`;
    }

    return this.httpService.put<IEditEmpresaBody>(endpoint, body);
  }

  public getDataForCuit(cuit: string): Observable<IEmpresaData> {
    return this.httpService.get<IEmpresaData>(`${this.basePath}/${cuit}`);
  }

  public checkCuit(cuit: string): Observable<ICheckCompanyToAssociate> {
    return this.httpService.get<ICheckCompanyToAssociate>(
      `${this.basePath}/comprobar-cuit/${cuit}`
    );
  }

  public createAssociation(
    companies: ICompanyToAssociate[]
  ): Observable<ISolicitudEmpresa[]> {
    return this.httpService.post<ISolicitudEmpresa[]>(
      `${this.basePath}/solicitar-asociacion`,
      { empresas: companies }
    );
  }

  public aproveAssociation(
    id: number,
    role: string,
    tipoNegocios: number[]
  ): Observable<ISolicitudEmpresa> {
    const body = { role, tipoNegocios };
    return this.httpService.put<ISolicitudEmpresa>(
      `${this.basePath}/aprobar-solicitud/${id}`,
      body
    );
  }

  public rejectAssociation(
    id: number,
    motivoDeRechazo?: string
  ): Observable<ISolicitudEmpresa> {
    const body = motivoDeRechazo ? { motivoDeRechazo } : undefined;
    return this.httpService.put<ISolicitudEmpresa>(
      `${this.basePath}/rechazar-solicitud/${id}`,
      body
    );
  }
}
