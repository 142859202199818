/* eslint-disable sort-keys */
export const PATHS = {
  AUTH: {
    BASE: 'auth',
    CUIT: {
      BASE: 'cuit',
      VALIDATING: 'validando-cuit',
    },
    ERROR: {
      BASE: 'error',
    },
    EXTERNAL: {
      BASE: 'externos',
      CODE: 'codigo',
    },
    INTERNAL: {
      BASE: 'internos',
    },
    LOGIN: {
      BASE: 'login',
    },
    RESET_PASSWORD: {
      BASE: 'reset-password',
    },
    SOCIAL_LOGIN: {
      BASE: 'social',
    },
    BUSINESS: {
      BASE: 'unidad-de-negocio',
    },
  },
  CARGA_FACTURAS_Y_REMITOS: {
    BASE: 'carga-de-facturas-y-remitos',
  },
  CONFIGURACION: {
    BASE: 'permisos',
  },
  CONTROL_DE_FLETES: {
    BASE: 'control-de-fletes',
  },
  DEPOSITOS: {
    BASE: 'depositos',
  },
  EMPRESAS: {
    BASE: 'empresas',
  },
  EMPTY: '',
  FACTURAS: {
    BASE: 'facturas',
  },
  MAIN: 'main',
  OFERTA_DE_CAMIONES: {
    BASE: 'oferta-de-camiones',
  },
  ORDENES_DE_CARGA: {
    BASE: 'ordenes-de-carga',
  },
  PERFIL: {
    BASE: 'perfil',
  },
  REMITOS: {
    BASE: 'remitos',
  },
  SIGNUP: {
    BASE: 'signup',
    SUCCESSFUL: 'validation-successful',
  },
  UN: {
    MARCAS: {
      BASE: 'marcas',
    },
    UNAGRO: {
      BASE: 'unagro',
    },
  },
  USER: {
    BASE: 'usuario',
  },
  WILDCARD: '**',
  UNDER_MAINTENANCE: {
    BASE: 'en-mantenimiento',
  },
};
