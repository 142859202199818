<!-- Paginador -->
<mat-paginator
  *ngIf="showPaginationControls"
  [pageSizeOptions]="
    matSortPaginator.pageSizeOptions ? matSortPaginator.pageSizeOptions : []
  "
  [length]="matSortPaginator.totalRows"
  [pageIndex]="matSortPaginator.pageIndex"
  [pageSize]="matSortPaginator.pageSize"
  (page)="onChangePage($event)"
  showFirstLastButtons>
</mat-paginator>

@if (showNoResultsMessage) {
  <app-no-results-message> </app-no-results-message>
} @else if (showNoDataMessage) {
  <app-no-data-message> </app-no-data-message>
} @else if (showEndOfScrollMessage) {
  <app-end-of-scroll></app-end-of-scroll>
}

<div class="flex col align-center spinner-container">
  <ngx-spinner
    [showSpinner]="showSpinner"
    class="ngx-spinner"
    bdOpacity="0.9"
    bdColor=""
    size="medium"
    color="#3c81bb"
    type="ball-beat"
    [fullScreen]="false">
  </ngx-spinner>
</div>
