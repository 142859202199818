<div class="copyright-wrapper">
  <span>by</span>
  <div class="wrap-logo-agd">
    <img
      class="logo-agd"
      src="../../../../assets/brand/AGD_white-01.svg"
      alt="AGD - Aceitera General Deheza S.A." />
  </div>
  <div class="divider"></div>
  <span>
    Aceitera General Deheza S.A.<br />
    &copy; 1948 - {{ year }}
  </span>
</div>
