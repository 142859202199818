import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-list-header',
  styleUrl: './list-header.component.scss',
  templateUrl: './list-header.component.html',
})
export class ListHeaderComponent {
  @Input() infitintScrollEnabled = false;
  @Input() showSpinner = false;
  @Output() paginationTypeSelected = new EventEmitter();

  onPaginationTypeClicked() {
    this.infitintScrollEnabled = !this.infitintScrollEnabled;
    this.paginationTypeSelected.emit(this.infitintScrollEnabled);
  }
}
