import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDateRangeInput } from '@angular/material/datepicker';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MOBILE_DIALOG_CONFIG } from '@app/core/constants/dialog-dialog.constants';
import * as NOTIFICATIONS from '@app/core/constants/notification.constants';
import { ROLES } from '@app/core/constants/roles.constants';
import { Company } from '@app/core/interfaces/auth/auth.interface';
import { ISearchFormGroup } from '@app/core/interfaces/shared/list.interface';
import { AuthService } from '@app/core/services/auth/auth.service';
import { TitleService } from '@app/core/services/communication/header-title/title.service';
import { SelectStateService } from '@app/core/services/communication/select-state.service';
import {
  DownloadExcelQuery,
  DownloadService,
} from '@app/core/services/download/download.service';
import { InfiniteScrollService } from '@app/core/services/infinite-scroll/infinite-scroll.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { DeviceHelper } from '@app/core/utils/device.helper';
import { FilterHelper } from '@app/core/utils/filter-helper';
import {
  IMatPaginator,
  IMatSort,
} from '@core/interfaces/utils/mat-table.interface';
import { DefaultMatPaginatorOptions } from '@core/utils/customMatPaginator';
import { saveAs } from 'file-saver';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'app-base-list',
  standalone: true,
  styles: '',
  template: '',
})
export abstract class BaseListComponent {
  public readonly ROLES = ROLES;
  public isMobil = false;
  public sortBy = 'id';
  public sortOrder: SortDirection = 'desc';
  public title = '';
  public globalSearchFilter = '';
  public showFilters = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public form: FormGroup<any>;
  public subledger: number | null = null;
  public currentCompany: Company | null = null;
  public userRole = '';
  public spinnerForFilterGrid = true; // Spinner para filtros en grilla
  public matSortPaginator: IMatPaginator & IMatSort = {
    ...new DefaultMatPaginatorOptions(),
    sortBy: this.sortBy,
    sortOrder: this.sortOrder,
    totalRows: 0,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected filtersApplied: any = {};
  protected selectStateService: SelectStateService;
  protected titleService: TitleService;
  protected authService: AuthService;
  protected notificationService: NotificationService;
  protected filterHelper: FilterHelper;
  protected downloadService: DownloadService;
  protected excelBasePath: string | null = null;
  protected deviceHelper: DeviceHelper;
  protected defaultDialogConfig = {
    maxHeight: '90vh',
    maxWidth: '90vw',
    minHeight: '243px',
    width: '50%',
  };
  public readonly roleWithCompanies: boolean;
  protected infitintScrollEnabled = false;
  protected isScrolling = false;
  protected infiniteScrollService: InfiniteScrollService;
  public endOfInfinitScroll = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatPaginator)
  paginator: MatPaginator = new MatPaginator(
    new MatPaginatorIntl(),
    ChangeDetectorRef.prototype
  );

  @ViewChild('globalSearch', { static: true })
  globalSearch: ElementRef<HTMLInputElement> = {} as ElementRef;

  constructor() {
    // Dependency Injection
    this.selectStateService = inject(SelectStateService);
    this.titleService = inject(TitleService);
    this.authService = inject(AuthService);
    this.notificationService = inject(NotificationService);
    this.filterHelper = inject(FilterHelper);
    this.deviceHelper = inject(DeviceHelper);
    this.downloadService = inject(DownloadService);
    this.infiniteScrollService = inject(InfiniteScrollService);

    this.form = new FormGroup<ISearchFormGroup>({
      globalSearch: new FormControl(),
    });

    const user = this.authService.getUser();
    this.userRole = user.role.tag;

    this.roleWithCompanies =
      this.userRole === ROLES.USUARIO_TRANSPORTE ||
      this.userRole === ROLES.USUARIO_CHOFER;

    if (this.roleWithCompanies) {
      const company = this.authService.getCompanySelected();
      this.currentCompany = company ?? null;
      this.subledger = company ? company.subledger : null;

      this.selectStateService.selectionChanged.subscribe((value: Company) => {
        this.currentCompany = value;
        this.subledger = value.subledger;
        this.getList();
      });
    }

    this.deviceHelper.isMobileDevice().then(isMobile => {
      this.isMobil = isMobile;

      if (isMobile) {
        this.defaultDialogConfig = MOBILE_DIALOG_CONFIG;
      }
    });

    this.infiniteScrollService.scrolledDownObserver().subscribe(() => {
      if (this.infitintScrollEnabled) {
        this.onScrollDown();
      }
    });
  }

  protected setTitle(title: string) {
    this.title = title;
    this.titleService.updateTitle(title);
  }

  protected setOrderConfig(sortBy: string, sortOrder: SortDirection) {
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;

    this.matSortPaginator = {
      ...new DefaultMatPaginatorOptions(),
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
      totalRows: 0,
    };
  }

  protected setExcelBasePath(basePath: string) {
    this.excelBasePath = basePath;
  }

  // Implement on child component
  protected abstract getList(): void;
  protected abstract requestList(pageIndex?: number): Observable<unknown>;

  sortData($event: Sort): void {
    this.matSortPaginator.pageIndex = 0;
    this.matSortPaginator.sortBy = $event.active;
    this.matSortPaginator.sortOrder = $event.direction;
    this.getList();
  }

  changePage($event: PageEvent): void {
    this.matSortPaginator.pageIndex = $event.pageIndex;
    this.matSortPaginator.pageSize = $event.pageSize;
    this.getList();
  }

  clearFilter($event: Event, controlNames: string[] | string): void {
    $event.stopPropagation();
    this.filterHelper.clearFilter(
      this.form,
      this.filtersApplied,
      Array.isArray(controlNames) ? controlNames : [controlNames]
    );
    this.getList();
  }

  clearFilters(globalSearchFilter = false): void {
    this.matSortPaginator.pageIndex = 0;
    if (!globalSearchFilter) {
      this.filtersApplied = {};
    }
    this.globalSearchFilter = '';
    this.form.reset();
    this.getList();
  }

  displayFilters(): void {
    this.showFilters = !this.showFilters;
  }

  filterList($event: MatSelectChange, column: string) {
    this.matSortPaginator.pageIndex = 0;
    this.filtersApplied[column] = $event.value;
    this.getList();
  }

  filterListByDate(
    dateRangeInput: MatDateRangeInput<Date>,
    formGroupName?: string
  ): void {
    this.matSortPaginator.pageIndex = 0;

    const { value } = dateRangeInput;

    if (value?.end && value?.start) {
      const dates = formGroupName
        ? {
            [formGroupName]: JSON.stringify({
              endDate: value.end,
              startDate: value.start,
            }),
          }
        : { endDate: value.end, startDate: value.start };

      this.filtersApplied = { ...this.filtersApplied, ...dates };
      this.getList();
    }
  }

  countFiltersApplied(includeAll = false): number {
    return (
      (includeAll && this.globalSearchFilter !== '' ? 1 : 0) +
      Object.keys(this.filtersApplied).length
    );
  }

  protected showNotification(message: string, config?: MatSnackBarConfig) {
    this.notificationService.showNotification(
      message,
      NOTIFICATIONS.CONSTANTS.CLOSE,
      { ...config }
    );
  }

  downloadExcel(): void {
    this.showNotification('Descargando archivo, por favor espere...');

    if (!this.excelBasePath) {
      return this.showNotification(
        'Error al descargar el archivo, asegúrese de establecer la ruta base del archivo correctamente.'
      );
    }

    const query: DownloadExcelQuery = {
      filters: this.filtersApplied,
      globalSearch: this.globalSearchFilter,
      sortBy: this.matSortPaginator.sortBy,
      sortOrder: this.matSortPaginator.sortOrder,
      subledger: this.subledger,
    };

    this.downloadService
      .getExcel(`${this.excelBasePath}/download-excel`, query)
      .subscribe({
        error: () => {
          this.showNotification('Error al descargar el archivo.');
        },
        next: res => {
          const contentDisposition = res?.headers.get('content-disposition');
          const fileName = contentDisposition
            .split(';')[1]
            .trim()
            .split('=')[1]
            .replace(/"/g, '');
          saveAs(res?.body, fileName);
        },
      });
  }

  piginationTypeButtonClicked(inifiniteEnabled: boolean): void {
    this.infitintScrollEnabled = inifiniteEnabled;
    // Si se hizo click en el boton de paginacion
    if (!this.infitintScrollEnabled) {
      this.isScrolling = false;
      this.endOfInfinitScroll = false;
      this.matSortPaginator.pageIndex = 0;
      this.matSortPaginator.pageSize = 10;
      this.getList();
    }
  }

  onScrollDown(): void {
    if (!this.isScrolling) {
      const pageIndex = ++this.matSortPaginator.pageIndex;
      this.isScrolling = true;

      this.requestList(pageIndex + 1)
        .pipe(take(1))
        .subscribe({
          error: (err: unknown) => {
            console.log(err);
            this.showNotification('Ocurrió un error, recargue la página.', {
              duration: 0,
            });
            this.isScrolling = false;
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          next: (data: any) => {
            if (!data.rows.length) {
              this.endOfInfinitScroll = true;
            }
            this.isScrolling = false;
            this.dataSource.data = [...this.dataSource.data, ...data.rows];
            this.matSortPaginator.totalRows = data.count;
          },
        });
    }
  }
}
