import { NgModule } from '@angular/core';

import { MarcasRoutingModule } from './marcas-routing.module';

@NgModule({
  declarations: [],
  imports: [MarcasRoutingModule],
  providers: [],
})
export class MarcasModule {}
