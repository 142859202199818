import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '@app/core/interfaces/user/user.interface';
import { PATHS } from '@app/core/interfaces/utils/router-path';
import { LocalStorageService } from '@app/core/services/localStorage/local-storage.service';

@Component({
  selector: 'app-business',
  styleUrl: './business.component.scss',
  templateUrl: './business.component.html',
})
export class BusinessComponent {
  public spinnerPage = false;
  public user = this.localStorageService.getItem('user') as IUser;
  public firstCompany = this.user.userCompanies[0];
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}
  public sendBusines(business: string) {
    this.router.navigate([
      `/${PATHS.MAIN}/${PATHS.UN.MARCAS.BASE}/${PATHS.CONTROL_DE_FLETES.BASE}`,
    ]);
    this.localStorageService.setItem('negocio', business);
  }
}
