import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates a CUIT value.
 *
 * @param control - The form control to validate.
 * @returns An object with an error if the CUIT is invalid, or null if it is valid.
 */
export function validaCuit(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const cuit: string = control.value?.replace(/[-_]/g, '') || '';
    const tipo: string = cuit.slice(0, 2);
    const prefijosValidos: number[] = [
      20,
      23,
      24,
      25,
      26,
      27,
      30, // Empresa
      33, // Empresa
      34, // Empresa
    ];
    if (
      !cuit ||
      prefijosValidos.indexOf(Number(tipo)) === -1 ||
      cuit.length !== 11
    ) {
      return { validaCuit: { valid: false } };
    }

    if (!/^\d*$/.test(cuit)) {
      return { validaCuit: { valid: false } };
    }

    const mult: number[] = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    let total = 0;
    for (let i = 0; i < mult.length; i++) {
      total += parseInt(cuit[i]) * mult[i];
    }
    const mod: number = total % 11;
    let digito: number;

    switch (mod) {
      case 0:
        digito = 0;
        break;
      case 1:
        digito = 9;
        break;
      default:
        digito = 11 - mod;
        break;
    }
    return digito === parseInt(cuit[10])
      ? null
      : { validaCuit: { valid: false } };
  };
}
