<!-- Spinner Page -->
<div class="spinner-fullscreen" [hidden]="!spinnerPage">
  <mat-spinner diameter="50" color="light-blue" class="mx-auto"></mat-spinner>
</div>
<!-- /Spinner Page -->

<div class="outer-container">
  <div class="header-container">
    <app-header-login></app-header-login>
  </div>

  <div class="forms-container">
    <div class="form-wrapper w-300">
      <h1 class="text-center">Verifique su identidad</h1>
      <p class="text-center mt-5 mb-15">
        Por la seguridad de tus datos, te enviaremos un código de verificación a
        la opción que elijas.
      </p>

      <mat-nav-list class="list-dark">
        <mat-list-item
          *ngFor="let level of levels"
          (click)="send2FACode(level.type)">
          <mat-icon *ngIf="level.type !== 'whatsApp'" matListItemIcon>{{
            level.type | lowercase
          }}</mat-icon>
          <mat-icon
            *ngIf="level.type === 'whatsApp'"
            matListItemIcon
            svgIcon="whatsapp"></mat-icon>
          <span matListItemTitle>{{ level.type | uppercase }}</span>
          <p matListItemLine>
            <span *ngIf="level.type === 'sms' || level.type === 'whatsApp'"
              >Al celular terminado en {{ level.contact }}</span
            >
            <span *ngIf="level.type === 'email'"
              >A la casilla {{ level.contact }}</span
            >
          </p>
          <div matListItemMeta>
            <mat-icon matListItemICon>chevron_right</mat-icon>
          </div>
        </mat-list-item>
      </mat-nav-list>

      <div class="simple-container">
        <button mat-fab extended class="button-transparent">
          ¿Necesitás ayuda?
        </button>
      </div>
    </div>
  </div>

  <app-copyright></app-copyright>
</div>
