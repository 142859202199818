import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS as LOCAL_STORAGE } from '@app/core/constants/local-storage.constants';
import { CONSTANTS } from '@app/core/constants/notification.constants';
import {
  AuthLoginResponse,
  LevelResponse,
  ResponseSendCode,
} from '@app/core/interfaces/auth/auth.interface';
import { PATHS } from '@app/core/interfaces/utils/router-path';
import { LocalStorageService } from '@app/core/services/localStorage/local-storage.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { AuthService } from '@core/services/auth/auth.service';
import { environment } from '@environment/environment';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: 'app-externos',
  templateUrl: './externos.component.html',
})
export class ExternosComponent {
  public levels: LevelResponse[];
  public spinnerPage = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private recaptchaService: ReCaptchaV3Service
  ) {
    this.levels = this.localStorageService.getItem(
      'levels'
    ) as Array<LevelResponse>;
  }

  public send2FACode(validationMethod: string) {
    this.spinnerPage = true;
    const token = this.localStorageService.getItem(
      LOCAL_STORAGE.TOKEN
    ) as string;
    this.recaptchaService
      .executeAsPromise(environment.RECAPTCHA_V3_SITE_KEY, 'send2FACode')
      .then(recaptcha => {
        // continue to 2FA
        this.authService
          .sendCodeToEmail(token, validationMethod, recaptcha)
          .subscribe({
            error: (err: HttpErrorResponse) => {
              this.notificationService.showNotification(
                err.error.message,
                CONSTANTS.CLOSE
              );
              setTimeout(() => {
                this.spinnerPage = false;
              }, 200);
            },
            next: (response: AuthLoginResponse<ResponseSendCode | string>) => {
              if (response.statusCode !== 201) {
                this.notificationService.showNotification(
                  response.statusText,
                  CONSTANTS.CLOSE
                );
              } else {
                const body = response.body as ResponseSendCode;
                // Ir a la pantalla de 2FA
                if (body.isCorrect) {
                  this.router.navigate([
                    `${PATHS.AUTH.BASE}/${PATHS.AUTH.EXTERNAL.BASE}/${PATHS.AUTH.EXTERNAL.CODE}`,
                    { method: validationMethod },
                  ]);
                }
              }
            },
          });
      })
      .catch(err => {
        this.spinnerPage = false;
        console.error(err);
        this.notificationService.showNotification(
          'Error de ReCaptcha, revise su conexión a internet o intente con otra red.'
        );
      });
  }
}
