export const environment = {
  API_URL: 'https://transportes.agd.com.ar/api_v2',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyBkdoNI6nfoiPAUOqRAp10SfvKNvzVqjxo',
    appId: '1:490170747289:web:fe350c3b1911adaf1f2b08',
    authDomain: 'agd-transportes-v2.firebaseapp.com',
    measurementId: 'G-HJ3MQ3XDP7',
    messagingSenderId: '490170747289',
    projectId: 'agd-transportes-v2',
    storageBucket: 'agd-transportes-v2.appspot.com',
    vapidKey:
      'BHjisWjDkhlUGh98pcwDknEedGzzDcm3YkS3sAdsiyjUmQQHDFlybqEp-lS4NjeEZlGo-mWfGZsFWIv0wJ_1R64',
  },
  GOOGLE_CLIENT_ID:
    '319550907028-h5n93hnbuft1ss06k1l1q66opj6ve93t.apps.googleusercontent.com',
  LOGIN_SEND_CODE_TIME: 60,
  MICROSOFT_CLIENT_ID: 'f3472785-c409-4d98-a54e-87e361f24029',
  MICROSOFT_LOGIN_SETTING: {
    authority: 'https://login.microsoftonline.com/common',
    logout_redirect_uri: 'https://transportes.agd.com.ar/auth/login',
    redirect_uri: 'https://transportes.agd.com.ar',
  },
  RECAPTCHA_V3_SITE_KEY: '6LfGcB4pAAAAAB7uBiOek8WhW2K3N9m52vc8NRmZ',
  SOCKET_URL: 'https://transportes.agd.com.ar',
  production: true,
};
