import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appCardDataCell]',
  standalone: true,
})
export class CardDataCellDirective implements AfterContentInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit() {
    const cardContent: HTMLElement = this.elementRef.nativeElement;
    const children: NodeListOf<HTMLElement> =
      cardContent.querySelectorAll(':scope > div');
    children.forEach(child => {
      child.classList.add('card-data-cell');
    });
  }
}
