import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class FilterHelper {
  clearFilter(
    form: FormGroup,
    filtersApplied: { [key: string]: string | number | boolean },
    controlNames: string[] = [],
    filterName?: string
  ): void {
    controlNames.forEach(controlName => {
      form.get(controlName)?.reset();
      delete filtersApplied[filterName ?? controlName];
    });
  }
}
