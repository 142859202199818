<div class="outer-container">
  <div class="wrap-logo-transportes">
    <img
      class="logo-transportes"
      src="../../../../assets/brand/Logo-Transportes.svg"
      alt="Transportes" />
    @if (environment !== 'production') {
      <mat-icon
        class="no-prod-icon"
        [matTooltip]="environment || ''"
        [svgIcon]="
          environment === 'development' ? 'develop-icon' : 'stage-icon'
        ">
      </mat-icon>
    }
  </div>

  <div class="forms-container">
    <h1 class="text-center">Iniciar sesión</h1>
    <span class="mb-20">Utilice sus credenciales asociadas a AGD</span>

    <form class="form-wrapper" [formGroup]="loginForm" (ngSubmit)="login()">
      <div>
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>E-mail</mat-label>
          <input
            class=""
            matInput
            type="email"
            formControlName="email"
            id="email" />
          <mat-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">
            Ingrese un email válido.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="simple-container">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Contraseña</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            id="password"
            autocomplete="off" />
          <mat-error *ngIf="invalidPassword">
            Ingrese una contraseña válida.
          </mat-error>
        </mat-form-field>
        <a href="/auth/reset-password" class="link"
          >¿Olvidaste tu contraseña?</a
        >
      </div>

      <div class="paragraphs mt-30 mb-30">
        <span
          >¿No es tu computadora? Usá el modo invitado para navegar de forma
          privada y segura.
          <a
            href="https://support.google.com/accounts?p=signin_privatebrowsing&hl=es"
            target="_blank"
            class="link"
            rel="noopener"
            >Más información</a
          ></span
        >
      </div>

      <div class="simple-container">
        <button
          mat-fab
          extended
          class="button-blue text-uppercase"
          type="submit"
          [disabled]="loginForm.invalid || mostrarSpinner">
          <span *ngIf="mostrarSpinner">
            <mat-spinner
              diameter="25"
              color="blue"
              class="mx-auto"></mat-spinner>
          </span>
          <span *ngIf="!mostrarSpinner">Iniciar Sesión</span>
        </button>
        <button
          type="button"
          mat-button
          extended
          class="button-transparent button-login mb-15"
          routerLink="/signup">
          Crear una cuenta
        </button>
      </div>
    </form>
    <mat-divider style="width: 300px" class="mt-20"></mat-divider>
    <app-oauth-login class="mb-15"></app-oauth-login>
  </div>
  <app-copyright></app-copyright>
</div>
