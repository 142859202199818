import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MEDIAMAP } from '@app/core/constants/query-constants';
import { Observable } from 'rxjs';

@Injectable()
export class DeviceHelper {
  constructor(public breackpointObserver: BreakpointObserver) {}

  protected breakpointStateChange(): Observable<BreakpointState> {
    return this.breackpointObserver.observe([
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small,
      Breakpoints.XSmall,
    ]);
  }

  public isMobileDevice(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.breakpointStateChange().subscribe({
        error: () => {
          reject(false);
        },
        next: res => {
          const isMobile = Object.keys(res.breakpoints).some(query => {
            if (res.breakpoints[query]) {
              const currentScreenSize = MEDIAMAP.get(query) ?? '';
              if (currentScreenSize === '599' || currentScreenSize === '960') {
                return true;
              }
            }

            return false;
          });

          resolve(isMobile);
        },
      });
    });
  }
  public isOnlyMobile(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.breakpointStateChange().subscribe({
        error: () => {
          reject(false);
        },
        next: res => {
          const isMobile = Object.keys(res.breakpoints).some(query => {
            if (res.breakpoints[query]) {
              const currentScreenSize = MEDIAMAP.get(query) ?? '';
              if (currentScreenSize === '599') {
                return true;
              }
            }

            return false;
          });

          resolve(isMobile);
        },
      });
    });
  }

  public getCurrentScreenSize(): Promise<string | false> {
    return new Promise((resolve, reject) => {
      this.breakpointStateChange().subscribe({
        error: () => {
          reject(false);
        },
        next: res => {
          const currentBreakpoint = Object.keys(res.breakpoints).find(
            query => res.breakpoints[query]
          );
          if (currentBreakpoint) {
            resolve(MEDIAMAP.get(currentBreakpoint) || '');
          } else {
            reject(false);
          }
        },
      });
    });
  }
}
