import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  MicrosoftLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OauthLoginComponent } from '@app/modules/auth/oauth-login/oauth-login.component';
import { ResetPasswordComponent } from '@app/modules/auth/reset-password/reset-password.component';
import { CopyrightModule } from '@app/shared/copyright/copyright.module';
import { HeaderLoginModule } from '@app/shared/header-login/header-login.module';
import { environment } from '@environment/environment';
import { ErrorAuthComponent } from '@modules/auth/2FA/error-auth/error-auth.component';
import { CodeAuthComponent } from '@modules/auth/2FA/externos/codigo-auth/codigo-auth.component';
import { ExternosComponent } from '@modules/auth/2FA/externos/externos.component';
import { InternosComponent } from '@modules/auth/2FA/internos/internos.component';
import { AuthRoutingModule } from '@modules/auth/auth-routing.module';
import { AddCuitComponent } from '@modules/auth/cuit-association/add-cuit/add-cuit.component';
import { ValidatingCuitComponent } from '@modules/auth/cuit-association/validating-cuit/validating-cuit.component';
import { LoginComponent } from '@modules/auth/login/login.component';
import { SocialLoginComponent } from '@modules/auth/social-login/social-login.component';

import { BusinessComponent } from './business/business.component';
import { CloseSessionModalComponent } from './close-session-modal/close-session-modal.component';

@NgModule({
  declarations: [
    LoginComponent,
    ExternosComponent,
    InternosComponent,
    ErrorAuthComponent,
    CodeAuthComponent,
    AddCuitComponent,
    ValidatingCuitComponent,
    ResetPasswordComponent,
    OauthLoginComponent,
    SocialLoginComponent,
    CloseSessionModalComponent,
    BusinessComponent,
  ],
  exports: [AddCuitComponent],
  imports: [
    CommonModule,
    GoogleSigninButtonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    AuthRoutingModule,
    CopyrightModule,
    HeaderLoginModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        onError: err => {
          console.error(err);
        },
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID, {
              oneTapEnabled: false,
            }),
          },
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider(
              environment.MICROSOFT_CLIENT_ID,
              environment.MICROSOFT_LOGIN_SETTING
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
})
export class AuthModule {}
