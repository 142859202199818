import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { EndOfScrollModule } from '@app/shared/end-of-scroll/end-of-scroll.module';
import { ListFooterModule } from '@app/shared/list-footer/list-footer.module';
import { ListHeaderModule } from '@app/shared/list-header/list-header.module';
import { NoDataMessageModule } from '@app/shared/no-data-message/no-data-message.module';
import { NoResultsMessageModule } from '@app/shared/no-results-message/no-result-message.module';
import { CardDataCellDirective } from '@assets/styles/directives/card-data-cell.directive';
import { TranslatedMatPaginator } from '@core/utils/customMatPaginator';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';

import { ListComponent } from './list/list.component';
import { RemitosRoutingModule } from './remitos-routing.module';

@NgModule({
  declarations: [ListComponent],
  imports: [
    CommonModule,
    RemitosRoutingModule,
    NoDataMessageModule,
    CardDataCellDirective,
    EndOfScrollModule,
    InfiniteScrollModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatMenuModule,
    NoResultsMessageModule,
    MatButtonToggleModule,
    NgxSpinnerModule,
    ListHeaderModule,
    ListFooterModule,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: TranslatedMatPaginator }],
})
export class RemitosModule {}
