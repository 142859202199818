import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PATHS } from '@core/interfaces/utils/router-path';

import { ErrorAuthComponent } from './2FA/error-auth/error-auth.component';
import { CodeAuthComponent } from './2FA/externos/codigo-auth/codigo-auth.component';
import { ExternosComponent } from './2FA/externos/externos.component';
import { InternosComponent } from './2FA/internos/internos.component';
import { BusinessComponent } from './business/business.component';
import { AddCuitComponent } from './cuit-association/add-cuit/add-cuit.component';
import { ValidatingCuitComponent } from './cuit-association/validating-cuit/validating-cuit.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SocialLoginComponent } from './social-login/social-login.component';

export const routes: Routes = [
  {
    component: LoginComponent,
    path: PATHS.AUTH.LOGIN.BASE,
  },
  {
    component: ExternosComponent,
    path: PATHS.AUTH.EXTERNAL.BASE,
  },
  {
    component: CodeAuthComponent,
    path: `${PATHS.AUTH.EXTERNAL.BASE}/${PATHS.AUTH.EXTERNAL.CODE}`,
  },
  {
    component: AddCuitComponent,
    path: PATHS.AUTH.CUIT.BASE,
  },
  {
    component: ValidatingCuitComponent,
    path: PATHS.AUTH.CUIT.VALIDATING,
  },
  {
    component: InternosComponent,
    path: PATHS.AUTH.INTERNAL.BASE,
  },
  {
    component: ErrorAuthComponent,
    path: PATHS.AUTH.ERROR.BASE,
  },
  {
    component: ResetPasswordComponent,
    path: PATHS.AUTH.RESET_PASSWORD.BASE,
  },
  {
    component: SocialLoginComponent,
    path: PATHS.AUTH.SOCIAL_LOGIN.BASE,
  },
  {
    component: BusinessComponent,
    path: PATHS.AUTH.BUSINESS.BASE,
  },
  {
    path: PATHS.WILDCARD,
    redirectTo: PATHS.AUTH.LOGIN.BASE,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class AuthRoutingModule {}
