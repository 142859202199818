import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  public showNotification(
    message: string,
    action = 'Cerrar',
    config?: MatSnackBarConfig
  ): void {
    const defaultConfig: MatSnackBarConfig = {
      duration: 6000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    };

    const mergedConfig = { ...defaultConfig, ...config };

    this.snackBar.open(message, action, mergedConfig);
  }
}
