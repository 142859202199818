import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as LOCALSTORAGE from '@app/core/constants/local-storage.constants';
import * as NOTIFICATION from '@app/core/constants/notification.constants';
import {
  CodeValidationForm,
  Success2FACodeValidationResponse,
} from '@app/core/interfaces/auth/auth.interface';
import { PATHS } from '@app/core/interfaces/utils/router-path';
import { LocalStorageService } from '@app/core/services/localStorage/local-storage.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { AuthService } from '@core/services/auth/auth.service';
import { environment } from '@environment/environment';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: 'app-codigo-auth',
  styleUrls: ['./codigo-auth.component.scss'],
  templateUrl: './codigo-auth.component.html',
})
export class CodeAuthComponent implements OnInit {
  leftTime = environment.LOGIN_SEND_CODE_TIME;
  showSendButton = false;
  showSendButtonSpinner = false;
  public mostrarSpinner: boolean;
  public validationMethod: string;
  public form: FormGroup<CodeValidationForm>;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private recaptchaService: ReCaptchaV3Service
  ) {
    this.form = new FormGroup<CodeValidationForm>({
      code: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d{6}$/),
      ]),
    });
    this.mostrarSpinner = false;
    this.validationMethod = this.route.snapshot.paramMap.get(
      'method'
    ) as string;
  }

  ngOnInit() {
    this.initCounter();
  }

  initCounter() {
    const interval = setInterval(() => {
      this.leftTime--;
      if (this.leftTime <= 0) {
        clearInterval(interval);
        this.showSendButton = true;
      }
    }, 1000);
  }

  public validate2FACode() {
    this.mostrarSpinner = true;
    this.recaptchaService
      .executeAsPromise(environment.RECAPTCHA_V3_SITE_KEY, 'send2FAValidation')
      .then(recaptcha => {
        const code = this.form.controls['code'].value as string;
        const token = this.localStorageService.getItem('token') as string;
        const email = this.localStorageService.getItem('email') as string;

        // continue to 2FA
        return this.authService
          .validate2FACode(code, email, token, recaptcha)
          .subscribe({
            error: (err: HttpErrorResponse) => {
              this.notificationService.showNotification(
                err.error.message,
                NOTIFICATION.CONSTANTS.CLOSE
              );
              this.mostrarSpinner = false;
            },
            next: (response: Success2FACodeValidationResponse) => {
              // Ir a la pantalla de 2FA
              this.authService.saveAndRedirect(response);
            },
          });
      })
      .catch(err => {
        console.error(err);
        this.notificationService.showNotification(
          'Error de ReCaptcha, revise su conexión a internet o intente con otra red.'
        );
      });
  }

  public send2FACode(validationMethod: string) {
    this.showSendButton = false;
    this.showSendButtonSpinner = true;
    this.recaptchaService
      .executeAsPromise(environment.RECAPTCHA_V3_SITE_KEY, 'send2FACode')
      .then(recaptcha => {
        const token = this.localStorageService.getItem(
          LOCALSTORAGE.CONSTANTS.TOKEN
        ) as string;

        // continue to 2FA
        this.authService
          .sendCodeToEmail(token, validationMethod, recaptcha)
          .subscribe({
            error: (err: HttpErrorResponse) => {
              this.showSendButton = true;
              this.notificationService.showNotification(
                err.error.message,
                NOTIFICATION.CONSTANTS.CLOSE
              );
              if (err.status === 400) {
                this.router.navigate([
                  `/${PATHS.AUTH.BASE}/${PATHS.AUTH.LOGIN.BASE}`,
                ]);
              }
            },
            next: () => {
              this.leftTime = environment.LOGIN_SEND_CODE_TIME;
              this.initCounter();
            },
          })
          .add(() => (this.showSendButtonSpinner = false));
      })
      .catch(err => {
        this.mostrarSpinner = false;
        console.error(err);
        this.notificationService.showNotification(
          'Error de ReCaptcha, revise su conexión a internet o intente con otra red.'
        );
      });
  }
}
