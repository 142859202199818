import { Component } from '@angular/core';

@Component({
  selector: 'app-end-of-scroll',
  styleUrl: './end-of-scroll.component.scss',
  templateUrl: './end-of-scroll.component.html',
})
export class EndOfScrollComponent {
  scrollToTop() {
    document.getElementsByClassName('mat-drawer-content')[0].scrollTo(0, 0);
  }
}
