import { Component } from '@angular/core';
import * as LOCALSTORAGE from '@app/core/constants/local-storage.constants';
import { LevelResponse } from '@app/core/interfaces/auth/auth.interface';
import { LocalStorageService } from '@app/core/services/localStorage/local-storage.service';

@Component({
  selector: 'app-social-login',
  styleUrls: ['./social-login.component.scss'],
  templateUrl: './social-login.component.html',
})
export class SocialLoginComponent {
  provider: LevelResponse[];
  constructor(private localStorageService: LocalStorageService) {
    this.provider = this.localStorageService.getItem(
      LOCALSTORAGE.CONSTANTS.LEVELS
    ) as LevelResponse[];
  }
}
