/**
 * Esto matchea los roles del backend
 */
export const ROLES = {
  ADMINISTRADOR: 'ADMINISTRADOR',
  ADMINISTRADOR_LOGISTICA: 'ADMINISTRADOR_LOGISTICA',
  ADMINISTRADOR_UNAGRO: 'ADMINISTRADOR_UNAGRO',
  USUARIO_CHOFER: 'CHOFER',
  USUARIO_LIQUIDADOR: 'LIQUIDADOR',
  USUARIO_PROGRAMADOR: 'PROGRAMADOR',
  USUARIO_SUPERVISOR: 'SUPERVISOR',
  USUARIO_TRANSPORTE: 'USUARIO_TRANSPORTE',
  VERIFICADOR_PALLETS: 'VERIFICADOR_PALLETS',
};

export const ROLES_IDS = {
  ADMINISTRADOR: 1,
  ADMINISTRADOR_LOGISTICA: 3,
  ADMINISTRADOR_UNAGRO: 2,
  USUARIO_CHOFER: 8,
  USUARIO_LIQUIDADOR: 7,
  USUARIO_PROGRAMADOR: 5,
  USUARIO_SUPERVISOR: 6,
  USUARIO_TRANSPORTE: 4,
  VERIFICADOR_PALLETS: 9,
};

export type RoleType = keyof typeof ROLES;
