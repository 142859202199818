import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import {
  IRemitosFilters,
  IRemitosFiltersApplied,
  IRemitosList,
} from '@app/core/interfaces/remitos/remitos.interfaces';
import { ListRequestParams } from '@app/core/interfaces/utils/mat-table.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RemitosService {
  private basePath = 'remitos';
  constructor(protected httpService: HttpService) {}
  /**
   *
   * @returns
   */

  public getBasePath(): string {
    return this.basePath;
  }
  public getList(
    globalSearch: string,
    filters: IRemitosFiltersApplied,
    page: number,
    limit: number,
    sortBy: string | null,
    sortOrder: string | null,
    subledger: number | null
  ): Observable<IRemitosList> {
    const params: ListRequestParams = { limit, page };
    if (sortBy && sortOrder) {
      params.sortBy = sortBy;
      params.sortOrder = sortOrder;
    }

    if (globalSearch) {
      params.globalSearch = globalSearch;
    }
    if (subledger) {
      params.subledger = subledger;
    }

    Object.keys(filters).forEach((item: string) => {
      if (typeof filters[item] === 'string') {
        //hay que hacer un trim porque en la db estan con espacios, esto viene desde microestrategy asi
        params[item] = filters[item].trim();
      } else {
        params[item] = filters[item];
      }
    });

    return this.httpService.get<IRemitosList>(`${this.basePath}`, params);
  }

  public getListFilters(): Observable<IRemitosFilters> {
    return this.httpService.get<IRemitosFilters>(`${this.basePath}/filtros`);
  }
}
