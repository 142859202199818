import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';

import { ListHeaderComponent } from './list-header.component';

@NgModule({
  declarations: [ListHeaderComponent],
  exports: [ListHeaderComponent],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatIconModule,
    NgxSpinnerModule,
  ],
})
export class ListHeaderModule {}
