import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/logged-in.guard';
import { PATHS } from '@core/interfaces/utils/router-path';

import { UnauthenticatedGuard } from './core/guards/not-logged-in.guard';

export const AppRoutes: Routes = [
  {
    canActivate: [UnauthenticatedGuard],
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
    path: PATHS.AUTH.BASE,
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/layout/layout.module').then(m => m.LayoutModule),
    path: PATHS.MAIN,
  },
  {
    canActivate: [UnauthenticatedGuard],
    loadChildren: () =>
      import('./modules/signup/signup.module').then(m => m.SignupModule),
    path: PATHS.SIGNUP.BASE,
  },
  {
    loadChildren: () =>
      import('./modules/maintenance/maintenance.module').then(
        m => m.MaintenanceModule
      ),
    path: PATHS.UNDER_MAINTENANCE.BASE,
  },
  {
    path: PATHS.WILDCARD,
    redirectTo: `${PATHS.AUTH.BASE}`,
  },
];

// configures NgModule imports and exports
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(AppRoutes, { enableTracing: false })],
})
export class AppRoutingModule {}
