<div class="flex col align-center h-460 mt-30">
  <div class="flex col align-center mt-30">
    <h2 class="gray-title">No hay datos disponibles en este momento.</h2>
    <div class="flex">
      <img src="../../../assets/images/no-data.svg" alt="" />
    </div>
    <ul>
      <li>Intente nuevamente <span class="grey-alt"> más tarde.</span></li>
      <li><span class="grey-alt"> Seleccione </span> otro transporte.</li>
    </ul>
  </div>
</div>
