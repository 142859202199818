import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PATHS } from '@core/interfaces/utils/router-path';

export const routes: Routes = [
  {
    path: PATHS.EMPTY,
    pathMatch: 'full',
    redirectTo: PATHS.CONTROL_DE_FLETES.BASE,
  },
  {
    canActivate: [],
    loadChildren: () =>
      import('./carga-facturas-y-remitos/carga-facturas-y-remitos.module').then(
        m => m.CargaFacturasYRemitosModule
      ),
    path: PATHS.CARGA_FACTURAS_Y_REMITOS.BASE,
  },
  {
    canActivate: [],
    loadChildren: () =>
      import('./control-de-fletes/control-de-fletes.module').then(
        m => m.ControlDeFletesModule
      ),
    path: PATHS.CONTROL_DE_FLETES.BASE,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class MarcasRoutingModule {}
