<div class="outer-container">
  <div class="wrap-logo-transportes">
    <img
      class="logo-transportes"
      src="../../../../assets/brand/Logo-Transportes.svg"
      alt="Transportes" />
  </div>
  <div class="forms-container">
    <form
      class="form-wrapper"
      [formGroup]="resetPasswordForm"
      (ngSubmit)="sendEmail(stepper)">
      <mat-stepper #stepper>
        <mat-step class="hide-step">
          <h1 class="tittle-h1">Reiniciar contraseña</h1>
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>E-mail</mat-label>
            <input
              matInput
              type="email"
              formControlName="email"
              id="email"
              autocomplete="off" />
            <mat-error *ngIf="email?.hasError('required')">
              Ingrese un email.
            </mat-error>
            <mat-error *ngIf="email?.hasError('email')">
              Ingrese un email válido.
            </mat-error>
          </mat-form-field>
          <div class="flex w-100">
            <div class="mr-auto">
              <button
                mat-fab
                extended
                class="button-blue h-40"
                routerLink="auth/login">
                Volver
              </button>
            </div>

            <div class="ml-auto">
              <button
                mat-fab
                extended
                class="button-blue h-40"
                type="submit"
                [disabled]="!resetPasswordForm.valid || showSpinner">
                <span *ngIf="!showSpinner">Enviar</span>
                <span *ngIf="showSpinner">
                  <mat-spinner
                    diameter="25"
                    color="blue"
                    class="mx-auto"></mat-spinner>
                </span>
              </button>
            </div>
          </div>
        </mat-step>

        <!-- ---------Paso 2------------ -->
        <mat-step class="hide-step">
          <div class="confirm">
            <mat-icon class="icon-72 green-icon mb-30">check_circle</mat-icon>
            <h1 class="w-328 text-center">Tu clave va a ser restablecida</h1>
            <div class="text-center w-320 mb-30">
              Chequear tu email. Introdujiste <strong>{{ email?.value }}</strong
              >. Si esta dirección de correo electrónico está asociada con tu
              usuario, enviaremos un mensaje con instrucciones para recuperar tu
              contraseña a ese correo.
            </div>

            <div class="simple-container w-320 mt-10">
              <button
                routerLink="auth/login"
                mat-fab
                extended
                class="button-blue w-full">
                Ir a Iniciar Sesión
              </button>
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </form>
  </div>
  <app-copyright></app-copyright>
</div>
