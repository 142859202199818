import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import { Observable } from 'rxjs';

export interface DownloadExcelQuery {
  globalSearch: string;
  filters: { [key: string]: string };
  sortBy: string | null;
  subledger: number | null;
  sortOrder: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private basePath = 'archivos';
  constructor(protected httpService: HttpService) {}
  /**
   *
   * @returns
   */
  public getFile(
    type: string,
    id: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this.httpService.downloadFile(`${this.basePath}/${type}/${id}`);
  }

  public getExcel(
    url: string,
    query: DownloadExcelQuery
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    const params = new URLSearchParams();
    if (query.sortBy && query.sortOrder) {
      params.set('sortBy', query.sortBy);
      params.set('sortOrder', query.sortOrder);
    }
    Object.keys(query.filters).forEach((item: string) => {
      if (typeof query.filters[item] === 'string') {
        params.set(item, query.filters[item].trim());
      } else {
        params.set(item, query.filters[item]);
      }
    });
    if (query.globalSearch) {
      params.set('globalSearch', query.globalSearch);
    }
    if (query.subledger) {
      params.set('subledger', query.subledger.toString());
    }

    return this.httpService.downloadFile(url + '?' + params.toString());
  }
}
