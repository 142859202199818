import {
  GoogleLoginProvider,
  MicrosoftLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { environment } from '@environment/environment';
import { HeaderLoginComponent } from '@shared/header-login/header-login.component';

@NgModule({
  declarations: [HeaderLoginComponent],
  exports: [HeaderLoginComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        onError: err => {
          console.error(err);
        },
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID),
          },
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider(
              environment.MICROSOFT_CLIENT_ID,
              environment.MICROSOFT_LOGIN_SETTING
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
})
export class HeaderLoginModule {}
