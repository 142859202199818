import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CONSTANTS } from '@app/core/constants/notification.constants';
import { ICompanyToAssociate } from '@app/core/interfaces/empresa/empresa.interface';
import { PATHS } from '@app/core/interfaces/utils/router-path';
import { EmpresaService } from '@app/core/services/empresa/empresa.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { formatCuitValue } from '@app/core/utils/cuitFormat';
import { validaCuit } from '@app/core/validators/cuit.validator';

@Component({
  selector: 'app-add-cuit',
  templateUrl: './add-cuit.component.html',
})
export class AddCuitComponent {
  empresas: ICompanyToAssociate[] = [];
  cuitForm: FormGroup = new FormGroup({});
  showSpinner = false;

  constructor(
    protected fb: FormBuilder,
    protected empresasService: EmpresaService,
    protected notificationService: NotificationService,
    protected router: Router
  ) {
    this.cuitForm = this.fb.group({
      cuit: [
        '',
        Validators.compose([
          Validators.required,
          validaCuit(),
          this.notRepeatedCuit(),
        ]),
      ],
    });
    this.cuit?.valueChanges.subscribe(value => {
      if (value) {
        this.cuitForm.controls['cuit'].markAsTouched();
        this.cuit?.setValue(formatCuitValue(value), { emitEvent: false });
      }
    });
  }

  get cuit() {
    return this.cuitForm.get('cuit');
  }

  notRepeatedCuit(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const value = parseInt(control.value.replace(/-/g, ''));
      const isRepeated = this.empresas.some(
        empresa => empresa.cuit_empresa === value
      );
      if (isRepeated) {
        return { isRepeated };
      }
      return null;
    };
  }

  addCompany() {
    this.showSpinner = true;
    if (this.cuit?.value) {
      this.empresasService
        .checkCuit(this.cuit.value.replace(/-/g, ''))
        .subscribe({
          error: (err: HttpErrorResponse) => {
            this.notificationService.showNotification(
              err.error.message,
              CONSTANTS.CLOSE
            );
            this.showSpinner = false;
            this.cuitForm.reset();
          },
          next: company => {
            this.empresas.push({
              cuit_empresa: company.cuit,
              razon_social_empresa: company.razonSocial,
              subledger_empresa: company.subledger,
            });
            if (this.empresas.length === 10) {
              this.cuit?.disable();
            }
            this.cuitForm.reset();
            this.showSpinner = false;
          },
        });
    }
  }

  remove(cuit: number): void {
    const filterCompanies = this.empresas.filter(
      company => company.cuit_empresa !== cuit
    );
    this.empresas = filterCompanies;
  }

  enviarSolicitud() {
    if (this.empresas.length) {
      this.empresasService.createAssociation(this.empresas).subscribe({
        error: (err: HttpErrorResponse) => {
          this.notificationService.showNotification(
            err.error.message,
            CONSTANTS.CLOSE
          );
        },
        next: () => {
          this.router.navigate([
            `/${PATHS.AUTH.BASE}/${PATHS.AUTH.CUIT.VALIDATING}`,
          ]);
        },
      });
    }
  }
}
