<div class="flex col align-center" *ngIf="showSpinner">
  <img
    src="../../../../assets/images/spinner-grid.svg"
    width="72px"
    alt="Cargando..." />
</div>

<mat-button-toggle-group
  class="toggle-group-pagination"
  appearance="legacy"
  name="fontStyle"
  aria-label="Font Style">
  <mat-button-toggle
    value="bold"
    [checked]="!infitintScrollEnabled"
    (click)="onPaginationTypeClicked()">
    Paginación
    <mat-icon
      color="accent"
      svgIcon="move_selection_left"
      aria-hidden="false"
      aria-label="Paginación"></mat-icon>
  </mat-button-toggle>
  <mat-button-toggle
    value="italic"
    [checked]="infitintScrollEnabled"
    (click)="onPaginationTypeClicked()">
    Carga Continua
    <mat-icon
      color="accent"
      svgIcon="move_selection_up"
      aria-hidden="false"
      aria-label="Carga Continua"></mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>
