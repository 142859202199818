import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as NOTIFICATIONS from '@app/core/constants/notification.constants';
import { CloseSessionModalComponent } from '@app/modules/auth/close-session-modal/close-session-modal.component';
import { PATHS } from '@core/interfaces/utils/router-path';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { HttpService } from '../http/http.service';
import { TokenResponse } from '../interfaces/auth/auth.interface';
import { AuthService } from '../services/auth/auth.service';
import { NotificationService } from '../services/notification/notification.service';
import { UserDataService } from '../services/user/user-data-service';
import { DeviceHelper } from '../utils/device.helper';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private requestCount = 1;

  constructor(
    public httpService: HttpService,
    public authService: AuthService,
    public userDataService: UserDataService,
    private router: Router,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    public deviceHelper: DeviceHelper
  ) {}

  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(req)
      .pipe(catchError(err => this.handleError(err, req, next)));
  }

  /**
   * Handles HTTP errors and performs necessary actions based on the error status.
   * @param error - The HTTP error response.
   * @param req - The HTTP request.
   * @param next - The HTTP handler.
   * @returns An observable of the HTTP event.
   */
  private handleError(
    error: HttpErrorResponse,
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Handle no connection error
    if (error.status === 0) {
      this.notificationService.showNotification(
        'Error al conectarse con el servidor.',
        NOTIFICATIONS.CONSTANTS.CLOSE
      );
      return EMPTY;
    }
    if (error.status === 403) {
      this.notificationService.showNotification(
        'Ocurrió un error al procesar tu solicitud. Intente nuevamente más tarde',
        NOTIFICATIONS.CONSTANTS.CLOSE
      );
      return EMPTY;
    }

    if (error.status === 503) {
      window.location.href = `${window.location.origin}/${PATHS.UNDER_MAINTENANCE.BASE}`;
      return EMPTY;
    }

    // Handle unauthorized error and refresh token
    if (
      error.status === 401 &&
      ['auth/validate-code', '/auth/login'].indexOf(this.router.url) === -1
    ) {
      return this.handle401Error(req, next);
    }

    // Handle other errors
    this.isRefreshing = false;
    return throwError(() => error);
  }

  handle401Error(req: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.authService.refreshTokenSubject.next(null);
      const refreshToken = this.userDataService.getRefreshToken();
      return this.authService.refreshToken(refreshToken).pipe(
        catchError(error => {
          this.notificationService.showNotification(
            'Tu sesión ha expirado. Por favor, vuelve a iniciar sesión para continuar.',
            NOTIFICATIONS.CONSTANTS.CLOSE
          );
          this.isRefreshing = false;
          this.openModalDisconnect();
          return throwError(() => error); // Devolver el error
        }),
        switchMap((res: TokenResponse) => {
          this.userDataService.setToken(res.token);
          this.userDataService.setRefreshToken(res.refreshToken);
          this.isRefreshing = false;
          this.authService.refreshTokenSubject.next(res);
          const headers = this.httpService.getHeaders();
          const newRequest = req.clone({ headers });
          return next.handle(newRequest);
        })
      );
    }
    if (req.url.includes('auth/refresh')) {
      this.notificationService.showNotification(
        'Tu sesión ha expirado. Por favor, vuelve a iniciar sesión para continuar.',
        NOTIFICATIONS.CONSTANTS.CLOSE
      );
      this.isRefreshing = false;
      this.openModalDisconnect();
    }
    // Queue all the requests that are being sent while refreshing the token
    // and handle them once the refreshing is completed
    return this.authService.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(() => {
        const headers = this.httpService.getHeaders();
        const newRequest = req.clone({ headers });
        return next.handle(newRequest);
      })
    );
  }

  public async openModalDisconnect() {
    let dialogConfig: MatDialogConfig = { autoFocus: true, width: '40%' };
    const isMobile = await this.deviceHelper.isMobileDevice();

    if (isMobile) {
      dialogConfig = {
        ...dialogConfig,
        maxWidth: '100%',
        width: 'calc(100% - 30px)',
      };
    }

    const dialogRef = this.dialog.open(
      CloseSessionModalComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(() => {
      this.authService.deleteLoginData();
      this.router.navigate([`/${PATHS.AUTH.BASE}/${PATHS.AUTH.LOGIN.BASE}`]);
    });
  }
}
