/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter, Injectable } from '@angular/core';
import { Company } from '@app/core/interfaces/auth/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class SelectStateService {
  selectionChanged = new EventEmitter<Company>();

  emitSelectionChanged(value: Company) {
    this.selectionChanged.emit(value);
  }
}
