import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private CHAT_THREADS = 'AGD_CHAT_THREADS';

  public setItem(key: string, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem(key: string): unknown {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }
  getChatThreads() {
    return this.getItem(this.CHAT_THREADS);
  }
  setChatThreads(data: unknown) {
    this.setItem(this.CHAT_THREADS, data);
  }
}
