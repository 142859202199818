<div class="header-login-wrapper flex column">
  <img
    class="logo-transportes"
    src="../../../../assets/brand/Logo-Transportes.svg"
    alt="Transportes" />

  @if (environment !== 'production') {
    <mat-icon
      class="no-prod-icon"
      [svgIcon]="environment === 'development' ? 'develop-icon' : 'stage-icon'">
    </mat-icon>
  }

  <mat-nav-list class="user-options">
    <mat-list-item [matMenuTriggerFor]="menu">
      <div matListItemAvatar>
        <mat-icon class="icon-17">person</mat-icon>
      </div>
      <span>{{ email }}</span>
    </mat-list-item>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logOut()">
        <mat-icon
          matListItemIcon
          class="material-icons-round mr-18 dark-grey-font"
          >power_settings_new</mat-icon
        >
        <div>
          <span class="mat-body-1 weight-normal dark-grey-font">Salir</span>
        </div>
      </button>
    </mat-menu>
  </mat-nav-list>
</div>
