import { NgModule } from '@angular/core';

import { NoDataMessageComponent } from './no-data-message.component';

@NgModule({
  declarations: [NoDataMessageComponent],
  exports: [NoDataMessageComponent],
  imports: [],
})
export class NoDataMessageModule {}
