import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  titleUpdated = new EventEmitter<string>();

  updateTitle(title: string) {
    this.titleUpdated.emit(title);
  }
}
