import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxSpinnerModule } from 'ngx-spinner';

import { EndOfScrollModule } from '../end-of-scroll/end-of-scroll.module';
import { NoDataMessageModule } from '../no-data-message/no-data-message.module';
import { NoResultsMessageModule } from '../no-results-message/no-result-message.module';
import { ListFooterComponent } from './list-footer.component';

@NgModule({
  declarations: [ListFooterComponent],
  exports: [ListFooterComponent],
  imports: [
    CommonModule,
    MatPaginatorModule,
    NgxSpinnerModule,
    NoResultsMessageModule,
    NoDataMessageModule,
    EndOfScrollModule,
  ],
})
export class ListFooterModule {}
