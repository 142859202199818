import { NgModule } from '@angular/core';

import { NoResultsMessageComponent } from './no-results-message.component';

@NgModule({
  declarations: [NoResultsMessageComponent],
  exports: [NoResultsMessageComponent],
  imports: [],
})
export class NoResultsMessageModule {}
