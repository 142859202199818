<!-- Spinner Page -->
<div class="spinner-fullscreen" [hidden]="!spinnerPage">
  <mat-spinner diameter="50" color="light-blue" class="mx-auto"></mat-spinner>
</div>
<!-- /Spinner Page -->

<div class="outer-container">
  <app-header-login></app-header-login>
  <div class="forms-container">
    <div class="form-wrapper w-300">
      <h1 class="h1-sm w-sm-full text-center h1-tittle">
        Seleccione una <br />
        unidad de negocio
      </h1>

      <mat-nav-list class="list-dark">
        <mat-list-item
          *ngFor="let businessType of firstCompany.company.companyBusinessTypes"
          (click)="sendBusines(businessType.businessType.nombre)">
          <div matListItemAvatar>
            <img
              class="toggle-icon"
              src="assets/icons/icon-{{ businessType.businessType.nombre }}.png"
              alt="{{ businessType.businessType.nombre }}"
              srcset="" />
          </div>
          <p class="mb-0" matListItemTitle>
            {{ businessType.businessType.nombre }}
          </p>
          <div matListItemMeta>
            <mat-icon class="mt-10" matListItemICon>chevron_right</mat-icon>
          </div>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </div>
  <app-copyright></app-copyright>
</div>
