import { Injectable } from '@angular/core';
import {
  MatPaginatorDefaultOptions,
  MatPaginatorIntl,
} from '@angular/material/paginator';

@Injectable()
export class TranslatedMatPaginator extends MatPaginatorIntl {
  constructor() {
    super();
    this.itemsPerPageLabel = 'Registros por página:';
    this.nextPageLabel = 'Próxima página';
    this.lastPageLabel = 'Última página';
    this.firstPageLabel = 'Primera página';
    this.previousPageLabel = 'Página anterior';
    this.getRangeLabel = this.getRangeLabelCustom;
    this.changes.next();
  }

  getRangeLabelCustom(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }
}

export class DefaultMatPaginatorOptions implements MatPaginatorDefaultOptions {
  pageSizeOptions = [10, 20, 50];
  totalRows = 0;
  pageIndex = 0;
  pageSize = 10;
}
