<div class="flex col align-center h-460 mt-30">
  <div class="flex col align-center mt-30">
    <h2 class="gray-title">No hay coincidencias en tu búsqueda.</h2>
    <div class="flex">
      <img src="../../../assets/images/not-found.svg" alt="" />
      <ul>
        <li>
          Revisa <span class="grey-alt"> los </span> parámetros
          <span class="grey-alt"> de los filtros.</span>
        </li>
        <li><span class="grey-alt"> Utiliza </span> menos filtros.</li>
      </ul>
    </div>
  </div>
</div>
