import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from '@app/core/interfaces/utils/router-path';

@Component({
  selector: 'app-validating-cuit',
  templateUrl: './validating-cuit.component.html',
})
export class ValidatingCuitComponent {
  constructor(private router: Router) {}

  onClick() {
    this.router.navigate([`/${PATHS.AUTH.BASE}/${PATHS.AUTH.CUIT.BASE}`]);
  }
}
