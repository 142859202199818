import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DeviceHelper } from '@app/core/utils/device.helper';
@Component({
  selector: 'app-close-session-modal',
  styleUrl: './close-session-modal.component.scss',
  templateUrl: './close-session-modal.component.html',
})
export class CloseSessionModalComponent {
  isMobil = false;
  constructor(
    public dialogRef: MatDialogRef<CloseSessionModalComponent>,
    public deviceHelper: DeviceHelper
  ) {
    this.deviceHelper.isMobileDevice().then(isMobile => {
      this.isMobil = isMobile;
    });
  }
}
