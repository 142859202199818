import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TITLES } from '@app/core/constants/title-page-constants';
import { Company } from '@app/core/interfaces/auth/auth.interface';
import {
  IRemitosFilters,
  IRemitosFiltersApplied,
  IRemitosSearchFormGroup,
} from '@app/core/interfaces/remitos/remitos.interfaces';
import { RemitosService } from '@app/core/services/microstrategy/remitos/remitos.service';
import { IDS_FILTERS } from '@core/constants/oferta-de-camiones.constants';
import { IRemitosList } from '@core/interfaces/remitos/remitos.interfaces';
import {
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  map,
  Observable,
  switchMap,
  take,
} from 'rxjs';

import { BaseListComponent } from '../../base-components/list/base-list.component';

@Component({
  selector: 'app-list',
  styleUrl: './list.component.scss',
  templateUrl: './list.component.html',
})
export class ListComponent extends BaseListComponent implements OnInit {
  override filtersApplied: IRemitosFiltersApplied = {};
  filters: IRemitosFilters = {
    casaMatriz: [],
    depositoOrden: [],
    numeroRemito: [],
    numeroViaje: [],
    transportista: [],
  };
  filtersLoaded = false;
  override form: FormGroup<IRemitosSearchFormGroup>;
  protected readonly IDS_FILTERS = IDS_FILTERS;

  constructor(private remitosService: RemitosService) {
    super();
    this.setTitle(TITLES.REMITOS.LIST);
    this.setExcelBasePath(this.remitosService.getBasePath());

    this.form = new FormGroup<IRemitosSearchFormGroup>({
      casaMatriz: new FormControl(),
      depositoOrden: new FormControl(),
      globalSearch: new FormControl(),
      numeroRemito: new FormControl(),
      numeroViaje: new FormControl(),
      transportista: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.getList();
    fromEvent(this.globalSearch.nativeElement, 'keyup')
      .pipe(
        map(e => (e.target as HTMLInputElement).value),
        debounceTime(10),
        distinctUntilChanged(),
        switchMap(text => {
          this.spinnerForFilterGrid = true;
          this.globalSearchFilter = text;
          return this.requestList();
        })
      )
      .subscribe((remitosData: IRemitosList) => {
        this.dataSource.data = remitosData.rows;
        this.matSortPaginator.totalRows = remitosData.count;
        this.spinnerForFilterGrid = false;
      });
    this.selectStateService.selectionChanged.subscribe((value: Company) => {
      this.subledger = value.subledger;
      this.getList();
    });
  }

  getListFilters(): void {
    this.remitosService
      .getListFilters()
      .pipe(take(1))
      .subscribe({
        error: (err: unknown) => {
          console.log(err);
        },
        next: (filters: IRemitosFilters) => {
          this.filters = filters;
        },
      });
  }

  requestList(pageIndex?: number): Observable<IRemitosList> {
    return this.remitosService.getList(
      this.globalSearchFilter,
      this.filtersApplied,
      pageIndex ?? this.matSortPaginator.pageIndex + 1,
      this.matSortPaginator.pageSize,
      this.matSortPaginator.sortBy,
      this.matSortPaginator.sortOrder,
      this.subledger
    );
  }

  getList(): void {
    this.endOfInfinitScroll = false;
    this.spinnerForFilterGrid = true;
    this.requestList()
      .pipe(take(1))
      .subscribe({
        error: () => {
          this.spinnerForFilterGrid = false;
          this.showNotification('Ocurrió un error, recargue la página.', {
            duration: 0,
          });
        },
        next: (remitosData: IRemitosList) => {
          this.dataSource.data = remitosData.rows;
          this.matSortPaginator.totalRows = remitosData.count;
          this.spinnerForFilterGrid = false;
        },
      });
  }

  override displayFilters(): void {
    if (!this.showFilters && !this.filtersLoaded) {
      this.getListFilters();
      this.filtersLoaded = true;
    }
    this.showFilters = !this.showFilters;
  }
}
