import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PATHS } from '@core/interfaces/utils/router-path';

import { ListComponent } from './list/list.component';

export const routes: Routes = [
  {
    component: ListComponent,
    path: PATHS.EMPTY,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class RemitosRoutingModule {}
