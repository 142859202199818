import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from '@app/core/interfaces/utils/router-path';

@Component({
  selector: 'app-error-auth',
  templateUrl: './error-auth.component.html',
})
export class ErrorAuthComponent {
  constructor(private router: Router) {}
  goToLogin() {
    this.router.navigate([`/${PATHS.AUTH.BASE}/${PATHS.AUTH.LOGIN.BASE}`]);
  }
}
