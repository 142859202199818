import { Injectable } from '@angular/core';
import { IInfiniteScrollEvent } from 'ngx-infinite-scroll';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfiniteScrollService {
  private scrolledDown = new Subject<IInfiniteScrollEvent>();

  public scrolledDownEmit(event: IInfiniteScrollEvent) {
    this.scrolledDown.next(event);
  }

  public scrolledDownObserver(): Observable<IInfiniteScrollEvent> {
    return this.scrolledDown.asObservable();
  }
}
