<!-- eslint-disable prettier/prettier -->
<div class="google-button" *ngIf="!this.provider || this.provider === 'GOOGLE'">
  <asl-google-signin-button
    text="continue_with"
    locale="es_AR"
    type="standard"
    size="large"
    shape="pill"
    [width]="300"
    >signin_with</asl-google-signin-button
  >
</div>
<button
  class="azure-button"
  text="signin_with"
  type="standard"
  size="large"
  (click)="signInWithAzure()"
  *ngIf="!this.provider || this.provider === 'MICROSOFT'">
  <mat-icon class="icon-mircrosoft" svgIcon="microsoft"></mat-icon>
  <p class="button-text">Continuar con Microsoft</p>
</button>
