<form [formGroup]="form" class="section-header">
  <div class="flex row align-center w-full gap-10 justify-between">
    <h1 class="mb-0 h1-sm">
      {{ matSortPaginator.totalRows }} Remitos encontrados
    </h1>
    <div class="flex row-sm w-full-sm justify-center">
      <button
        mat-button
        class="button-w-icon-suffix"
        (click)="displayFilters()">
        <mat-icon>filter_alt_rounded</mat-icon>Filtrar ({{
          countFiltersApplied()
        }})
      </button>
      <button
        mat-button
        *ngIf="countFiltersApplied() !== 0 || globalSearchFilter.length > 0"
        (click)="clearFilters(false)">
        Limpiar Filtros
      </button>
    </div>
  </div>

  <div class="common-filters w-full gap-10 justify-between">
    <mat-form-field appearance="outline" class="w-full-sm">
      <input
        matInput
        formControlName="globalSearch"
        placeholder="Buscar"
        #globalSearch />
      <button
        *ngIf="this.globalSearch.value"
        matSuffix
        mat-icon-button
        aria-label="Borrar filtro"
        (click)="clearFilters(true)">
        <mat-icon>close</mat-icon>
      </button>
      <mat-icon matSuffix *ngIf="!this.globalSearch.value"
        >search_rounded</mat-icon
      >
    </mat-form-field>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        [disabled]="
          !authService.hasPermission('MAIN.REMITOS.LIST.DOWNLOAD_EXCEL')
        "
        (click)="downloadExcel()">
        Descargar gilla actual en Excel(.XLS)
      </button>
    </mat-menu>
  </div>

  <!-- Custom filters -->
  <div [style.display]="!showFilters ? 'none' : ''" class="custom-filters">
    <mat-form-field
      appearance="outline"
      *ngIf="!roleWithCompanies"
      [ngClass]="filtersApplied['transportista'] ? 'selected' : ''">
      <mat-select
        formControlName="transportista"
        class="select-wo-arrow"
        placeholder="Transportista"
        (selectionChange)="filterList($event, 'transportista')">
        <mat-option *ngFor="let item of filters.transportista" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
      <mat-icon
        *ngIf="filtersApplied['transportista']"
        matSuffix
        class="pointer"
        aria-label="Borrar filtro"
        (click)="clearFilter($event, 'transportista')"
        >close</mat-icon
      >
      <mat-icon *ngIf="!filtersApplied['transportista']" matSuffix
        >expand_more_rounded</mat-icon
      >
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      [ngClass]="filtersApplied['depositoOrden'] ? 'selected' : ''">
      <mat-select
        formControlName="depositoOrden"
        class="select-wo-arrow"
        placeholder="Depósito Orden"
        (selectionChange)="filterList($event, 'depositoOrden')">
        <mat-option *ngFor="let item of filters.depositoOrden" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
      <mat-icon
        *ngIf="filtersApplied['depositoOrden']"
        matSuffix
        class="pointer"
        aria-label="Borrar filtro"
        (click)="clearFilter($event, 'depositoOrden')"
        >close</mat-icon
      >
      <mat-icon *ngIf="!filtersApplied['depositoOrden']" matSuffix
        >expand_more_rounded</mat-icon
      >
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      [ngClass]="filtersApplied['casaMatriz'] ? 'selected' : ''">
      <mat-select
        formControlName="casaMatriz"
        class="select-wo-arrow"
        placeholder="Casa Matriz"
        (selectionChange)="filterList($event, 'casaMatriz')">
        <mat-option *ngFor="let item of filters.casaMatriz" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
      <mat-icon
        *ngIf="filtersApplied['casaMatriz']"
        matSuffix
        class="pointer"
        aria-label="Borrar filtro"
        (click)="clearFilter($event, 'casaMatriz')"
        >close</mat-icon
      >
      <mat-icon *ngIf="!filtersApplied['casaMatriz']" matSuffix
        >expand_more_rounded</mat-icon
      >
    </mat-form-field>
  </div>
</form>

<!-- Grid with results -->
<div *ngIf="this.matSortPaginator.totalRows > 0">
  <app-list-header
    [infitintScrollEnabled]="infitintScrollEnabled"
    [showSpinner]="spinnerForFilterGrid"
    (paginationTypeSelected)="piginationTypeButtonClicked($event)">
  </app-list-header>
  <!-- Card Header -->
  <mat-card
    matSort
    (matSortChange)="sortData($event)"
    matSortActive="id"
    matSortDirection="desc"
    *ngIf="!isMobil">
    <mat-card-content appCardDataCell>
      <div matColumnDef="depositoOrden" mat-sort-header>
        <p class="typo-1">Depósito</p>
      </div>
      <div matColumnDef="numeroViaje" mat-sort-header>
        <p class="typo-1">Viaje</p>
      </div>
      <div
        *ngIf="!roleWithCompanies"
        matColumnDef="transportista"
        mat-sort-header>
        <p class="typo-1">Transportista</p>
      </div>
      <div matColumnDef="casaMatriz" mat-sort-header>
        <p class="typo-1">Cliente</p>
      </div>
      <div matColumnDef="numeroRemito" mat-sort-header>
        <p class="typo-1">Remito</p>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- Card Row -->
  <mat-card *ngFor="let remito of dataSource.data">
    <mat-card-content appCardDataCell>
      <div>
        <p *ngIf="isMobil" class="typo-1 header">Depósito</p>
        <p class="typo-2">{{ remito.depositoOrden }}</p>
      </div>
      <div>
        <p *ngIf="isMobil" class="typo-1 header">Viaje</p>
        <p class="typo-1">N.º {{ remito.numeroViaje }}</p>
      </div>
      <div *ngIf="!roleWithCompanies">
        <p *ngIf="isMobil" class="typo-1 header">Transportista</p>
        <p class="typo-1">
          {{ remito.transportista }}
        </p>
      </div>
      <div>
        <p *ngIf="isMobil" class="typo-1 header">Cliente</p>
        <p class="typo-1">{{ remito.casaMatriz }}</p>
      </div>
      <div>
        <p *ngIf="isMobil" class="typo-1 header">Remito</p>
        <p class="typo-1">
          {{ remito.numeroRemito }}
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<app-list-footer
  [matSortPaginator]="matSortPaginator"
  [showPaginationControls]="
    matSortPaginator.totalRows > 0 && !infitintScrollEnabled
  "
  [showNoResultsMessage]="
    matSortPaginator.totalRows === 0 && countFiltersApplied(true) >= 1
  "
  [showNoDataMessage]="
    matSortPaginator.totalRows === 0 && countFiltersApplied(true) === 0
  "
  [showEndOfScrollMessage]="endOfInfinitScroll"
  [showSpinner]="isScrolling && infitintScrollEnabled"
  (changePage)="changePage($event)">
</app-list-footer>
