import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { EndOfScrollComponent } from './end-of-scroll.component';

@NgModule({
  declarations: [EndOfScrollComponent],
  exports: [EndOfScrollComponent],
  imports: [MatButtonModule, MatIconModule],
})
export class EndOfScrollModule {}
