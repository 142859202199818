import { Injectable } from '@angular/core';
import { CONSTANTS } from '@app/core/constants/local-storage.constants';

import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService extends LocalStorageService {
  setUser(user: unknown): void {
    this.setItem(CONSTANTS.USER, user);
  }

  setPermissions(permissions: unknown): void {
    this.setItem(CONSTANTS.PERMISSIONS, permissions);
  }

  setRefreshToken(refreshToken: string) {
    this.setItem(CONSTANTS.REFRESH_TOKEN, refreshToken);
  }

  setToken(token: string) {
    this.setItem(CONSTANTS.TOKEN, token);
  }

  getRefreshToken(): string {
    return this.getItem(CONSTANTS.REFRESH_TOKEN) as string;
  }

  getToken(): string {
    return this.getItem(CONSTANTS.TOKEN) as string;
  }

  getUser() {
    return this.getItem(CONSTANTS.USER);
  }

  getPermissions() {
    return this.getItem(CONSTANTS.PERMISSIONS);
  }

  removeRefreshToken() {
    this.removeItem(CONSTANTS.REFRESH_TOKEN);
  }

  removeToken() {
    this.removeItem(CONSTANTS.TOKEN);
  }

  removeCompanySelected() {
    this.removeItem(CONSTANTS.COMPANY_SELECTED);
  }

  removeLevels() {
    this.removeItem(CONSTANTS.LEVELS);
  }

  removeUser() {
    this.removeItem(CONSTANTS.USER);
  }

  removePermissions() {
    this.removeItem(CONSTANTS.PERMISSIONS);
  }
}
